import React, { useEffect, useState } from "react";
import PlayPause from "./PlayPause";
import { playPause, setActiveSong } from "../redux/features/playerSlice";
import PORT from "../const";
import { useDispatch } from "react-redux";
import { findCategory, sendCount } from "../api";

const LatestSongItem = ({ song, isPlaying, activeSong, data, i }) => {
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState("Unknown Category"); // Initialize with a default value
  useEffect(() => {
    // Fetch category name based on song.category
    const fetchCategoryName = async () => {
      try {
        findCategory(song.category)
          .then((res) => {
            // console.log(res.latest);
            const data = res.data.category;
            console.log("Fetched category name:", data.name);
            setCategoryName(data.name);
          })
          .catch((error) => {
            console.error("Error fetching latest songs:", error);
          });
      } catch (error) {
        console.error("Error fetching category name:", error);
      }
    };

    // Call the fetchCategoryName function
    fetchCategoryName();
  }, [song.category]);

  const handlePauseClick = () => {
    dispatch(playPause(false));
  };

  const handlePlayClick = async () => {
    // console.log("index", i);
    dispatch(setActiveSong({ song, data, i }));
    await sendCount(song.id);
    dispatch(playPause(true));
  };
  return (
    <div className="row justify-content-start w-100 mb-1">
    <div className="col-md-12 text-white d-flex justify-content-start align-items-center mx-3 cus-nav">
      <div className="col-2">
        <img  src={song.poster ? PORT + song.poster : '/assets/images/TheBuzzThumb.png'} alt="" className="img-fluid latest-img br-15" />
      </div>
      <div className="col-7">
        <div className="d-flex flex-column justify-content-start align-items-start mx-2">
          <p className="quicksand text-truncate text-break text-capitalize fs-14" style={{ maxWidth: '100%' }}>
            {song.name}
          </p>
          <small className="cus-text-p josefin-sans">{categoryName}</small>
        </div>
      </div>
  
      <div className="col-3 float-end">
        <div
          className="music-play float-end"
          data-music-url={song.audio_file}
          data-category-id={song.category}
        >
          <small
            className="text-white p-2 playpause-icon play-button"
            data-state="play"
          >
            <PlayPause
              isPlaying={isPlaying}
              activeSong={activeSong}
              song={song}
              handlePause={handlePauseClick}
              handlePlay={handlePlayClick}
            />
          </small>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default LatestSongItem;
