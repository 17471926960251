import React from 'react';

const Seekbar = ({ value, min, max, onInput, setSeekTime, appTime }) => {
  // converts the time to format 0:00
  const getTime = (time) => `${Math.floor(time / 60)}:${(`0${Math.floor(time % 60)}`).slice(-2)}`;

  return (
    <div className="d-flex  flex-row align-items-center justify-content-center gap-1">
      <button type="button" onClick={() => setSeekTime(appTime - 5)} className="d-none hover-effect d-lg-block btn btn-link text-white">
        -
      </button>
      <p className="text-white">{value === 0 ? '0:00' : getTime(value)}</p>
      <div className='progress custom-progress w-100'><input
        type="range"
        step="any"
        value={value}
        min={min}
        max={max}
        onInput={onInput}
        className="d-md-block rounded-lg progress-bar custom-progress-bar hover-effect"
      /></div>
      <p className="text-white">{max === 0 ? '0:00' : getTime(max)}</p>
      <button type="button" onClick={() => setSeekTime(appTime + 5)} className="d-none d-lg-block btn btn-link text-white hover-effect">
        +
      </button>
    </div>
  );
};

export default Seekbar;
