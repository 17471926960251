// index.js

import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
     
        <App />
        <ToastContainer
       position="bottom-center" />
     
    </Provider>
    </BrowserRouter>
);
      



