// src/components/HeaderComponent.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findCategory, findSongs } from "../api";
import PORT from "../const";
import { Link, useNavigate } from "react-router-dom";
import {
  setActiveSong,
  setCategories,
  updateSearchResults,
} from "../redux/features/playerSlice";

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const searchResults = useSelector((state) => state.player.search.results);
  const navigate = useNavigate();

  const handleInputChange = async (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);

    if (newValue.trim() === "") {
      dispatch(updateSearchResults([]));
      return;
    }

    try {
      const response = await findSongs(newValue);
      const fetchCategoryDetails = response.data.search.map((result) =>
        findCategory(result.category)
          .then((categoryResponse) => categoryResponse.data.category)
          .catch((error) => {
            console.error(
              `Error fetching category details for ${result.category}:`,
              error
            );
            return null;
          })
      );

      const categoryDetails = await Promise.all(fetchCategoryDetails);

      const updatedResults = response.data.search.map((result, index) => ({
        ...result,
        categoryDetails: categoryDetails[index],
      }));

      dispatch(updateSearchResults(updatedResults));
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleSongClick = (result) => {
    startPlayingSong(result);
    navigateToCategoryPage(result.category);
  };

  const startPlayingSong = (song) => {
    dispatch(setActiveSong(song));
    // Assuming there is a play action in your redux slice
    // dispatch(play());
  };
  useEffect(() => {
    // Trigger a new search after navigation
    handleInputChange({ target: { value: searchValue } });
  }, [searchValue]);

  const navigateToCategoryPage = (categoryId) => {
    navigate(`/category/${categoryId}`);
  };
  return (
    <div className="row d-flex align-items-start justify-content-between">
    <div className="col-md-12 d-flex align-items-start justify-content-between">
      <div className="col-xs-12 col-sm-12 col-md-12 w-100 d-flex gap-3 mt-4">
        {/* Search bar content */}
        <div className="w-100 text-light d-flex align-items-center br-15 gap-3 py-1">
          <i className="fa fa-search cus-icon" aria-hidden="true"></i>
          <input
            type="search"
            className="form-control br-15"
            placeholder="I want to listen...."
            id="song-search"
            value={searchValue}
            onChange={handleInputChange}
          />
        </div>
  
       
        <div className="d-flex justify-content-center align-items-center  br-15">
          {/* Bell icon content */}
          <a href="#connect" className="text-decoration-none">
            <div className="text-white cus-dark p-3 br-15">
              <i className="fa fa-bell text-white" aria-hidden="true"></i>
            </div>
          </a>
        </div>
        <Link className="text-decoration-none d-flex justify-content-center align-items-center br-15">
          <img
            src="./assets/img/logo-blue.png"
            className="cus-img img-fluid m-1 bg-white br-15"
            alt=""
          />
        </Link>
      </div>
    </div>
     {/* Search results container */}
     {searchResults && searchResults.length > 0 && (
          <div className="position-relative w-100 mt-2">
            <div
              className="search-results-container br-15 cus-dark text-white"
              style={{              
                zIndex: 999,
                width: "100%",
                position: "absolute", // Change position to relative
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  className="col-md-12 d-flex justify-content-start align-items-center mx-3  p-2"
                  onClick={() => handleSongClick(result)}
                >
                  <div className="col-1">
                    <img
                      src={
                        result.poster
                          ? PORT + result.poster
                          : "/assets/images/TheBuzzThumb.png"
                      }
                      alt=""
                      className="img-fluid latest-img br-15"
                    />
                  </div>
                  <div className="col-11">
                    <div className="d-flex flex-column justify-content-start align-items-start mx-2">
                      <p className="quicksand fs-14">{result.name}</p>
                      <small className="cus-text-p josefin-sans">
                        {result.categoryDetails && result.categoryDetails.name}
                      </small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
  
  </div>
  
  );
};

export default HeaderComponent;
