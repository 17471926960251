import React from 'react';
import {  BsFillVolumeUpFill, BsVolumeDownFill, BsVolumeMuteFill,  } from 'react-icons/bs';

const VolumeBar = ({ value, min, max, onChange, setVolume }) => (
  <div className="d-none d-lg-flex flex-grow-1 align-items-center justify-end">
    {value <= 1 && value > 0.5 && <BsFillVolumeUpFill size={25} className='hover-effect' color="#FFF" onClick={() => setVolume(0)} />}
    {value <= 0.5 && value > 0 && <BsVolumeDownFill size={25} className='hover-effect' color="#FFF" onClick={() => setVolume(0)} />}
    {value === 0 && <BsVolumeMuteFill size={25} className='hover-effect' color="#FFF" onClick={() => setVolume(1)} />}
    <div className='progress custom-progress'>
    <input
      type="range"
      step="any"
      value={value}
      min={min}
      max={max}
      onChange={onChange}
      className="progress-bar custom-progress-bar hover-effect"
    />
    </div>
  </div>
);

export default VolumeBar;
