import React from "react";
import Home from "./pages/home";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./pages/about";

function App() {
  return(

  <Routes>
    <Route path="*" element={<Home />} />
    <Route path="/about" element={<AboutUs />} />
  </Routes>);
}
export default App;
