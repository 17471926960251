// src/components/PodcastComponent.js

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Image, Row, Col } from 'react-bootstrap';
import PORT from "../const";
import { getSongsByCategory } from "../api";

const PodcastComponent = () => {
  const { selectedCategoryId } = useParams();
  const [categoryDetails, setCategoryDetails] = useState(null);

  useEffect(() => {
    fetchSongs(selectedCategoryId);
  }, [selectedCategoryId]);

  const fetchSongs = async (categoryId) => {
    try {
      const response = await getSongsByCategory(categoryId);
      if (response && response.cur_cat) {
        setCategoryDetails(response.cur_cat);
      }
    } catch (error) {
      console.error("Error fetching songs:", error);
    }
  };

  if (!categoryDetails) {
    return null;
  }

  return (
    <Row>
      <Col md={12} className="d-md-block d-none image-container-play position-relative">
        <Image src={PORT + categoryDetails.banner} alt="" fluid className="w-100 img-fluid cus-img-play br-15" />
        <div className="position-absolute bottom-0 start-0 mx-3 text-white p-4 rounded">
          <h2 className="mb-3 d-inline-block">{categoryDetails.name}</h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" className="mb-3" viewBox="0 0 25 25" fill="none">
            {/* ... SVG Path ... */}
            <path
                    d="M22.4583 11.1875L21.0417 9.54163C20.7708 9.22913 20.5521 8.64579 20.5521 8.22913V6.45829C20.5521 5.35413 19.6458 4.44788 18.5417 4.44788H16.7708C16.3646 4.44788 15.7708 4.22913 15.4583 3.95829L13.8125 2.54163C13.0938 1.92704 11.9167 1.92704 11.1875 2.54163L9.55209 3.96871C9.23959 4.22913 8.64584 4.44788 8.23959 4.44788H6.43751C5.33334 4.44788 4.42709 5.35413 4.42709 6.45829V8.23954C4.42709 8.64579 4.20834 9.22913 3.94792 9.54163L2.54167 11.1979C1.93751 11.9166 1.93751 13.0833 2.54167 13.802L3.94792 15.4583C4.20834 15.7708 4.42709 16.3541 4.42709 16.7604V18.5416C4.42709 19.6458 5.33334 20.552 6.43751 20.552H8.23959C8.64584 20.552 9.23959 20.7708 9.55209 21.0416L11.1979 22.4583C11.9167 23.0729 13.0938 23.0729 13.8229 22.4583L15.4688 21.0416C15.7813 20.7708 16.3646 20.552 16.7813 20.552H18.5521C19.6563 20.552 20.5625 19.6458 20.5625 18.5416V16.7708C20.5625 16.3645 20.7813 15.7708 21.0521 15.4583L22.4688 13.8125C23.0729 13.0937 23.0729 11.9062 22.4583 11.1875ZM16.8333 10.5312L11.8021 15.5625C11.6563 15.7083 11.4583 15.7916 11.25 15.7916C11.0417 15.7916 10.8438 15.7083 10.6979 15.5625L8.17709 13.0416C7.87501 12.7395 7.87501 12.2395 8.17709 11.9375C8.47917 11.6354 8.97917 11.6354 9.28126 11.9375L11.25 13.9062L15.7292 9.42704C16.0313 9.12496 16.5313 9.12496 16.8333 9.42704C17.1354 9.72913 17.1354 10.2291 16.8333 10.5312Z"
                    fill="url(#paint0_linear_1_283)" />
                <defs>
                    <linearGradient id="paint0_linear_1_283" x1="12.5042" y1="2.08121" x2="12.5042"
                        y2="22.9198" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#138BD7" />
                        <stop offset="1" stopColor="#62AAFB" />
                    </linearGradient>
                </defs>
          </svg>
          <p className="mb-4 ">{categoryDetails.count} Total Listeners</p>
        </div>
        {/* <Image src='../../assets/img/logo-white.png' alt="Logo" className="img-fluid w-25 position-absolute top-0 end-0 m-4" /> */}
      </Col>
      <h2 className="mb-3 d-md-none  text-white d-inline-block">{categoryDetails.name}</h2>
    </Row>
  );
}

export default PodcastComponent;
 