// src/components/MusicListItem.js

import React, { useEffect, useState } from "react";
import PlayPause from "./PlayPause"; // Make sure to import PlayPause component
import PORT from "../const";
import { useDispatch } from "react-redux";
import { playPause, setActiveSong } from "../redux/features/playerSlice";
import { findArtists, sendCount } from "../api";
import { toast } from "react-toastify";
import { MetaTags } from 'react-meta-tags';

const MusicListItem = ({ song, isPlaying, activeSong, data, i }) => {
  const dispatch = useDispatch();
  const [artists, setArtists] = useState([]);

  const handlePauseClick = () => {
    dispatch(playPause(false));
  };

  const handlePlayClick = async () => {
    // console.log("index", i);
    dispatch(setActiveSong({ song, data, i }));
    await sendCount(song.id);
    dispatch(playPause(true));
  };

  // const handleShareClick = (songId, categoryId) => {
  //   const categoryUrl = `http://localhost:3000/category/${categoryId}`;
  //   const songUrl = `${categoryUrl}/song/${songId}`;

  //   if (navigator.share) {
  //     try {
  //       navigator.share({
  //         title: "Share Song",
  //         text: "Check out this song!",
  //         url: songUrl,
  //       });
  //     } catch (error) {
  //       console.error("Error sharing:", error);
  //     }
  //   } else {
  //     console.log("Web Share API not supported");
  //   }
  // };
  // Import navigator.share
  const handleCopyLink = () => {
  // Update the meta tags with the cover image URL of the currently playing song
    if (activeSong && activeSong.poster) {
      const metaTags = [
        { property: 'og:title', content: activeSong.name },
        { property: 'og:image', content: activeSong.poster ? activeSong.poster : "/assets/images/TheBuzzThumb.png" },
        // Add more metadata tags as needed
      ];
      // Set metadata using react-meta-tags
      // This will update the <head> of the document
      return (
        <MetaTags>
          {metaTags.map((tag, index) => <meta key={index} {...tag} />)}
        </MetaTags>
      );
    }

  
    // Copy the category URL to the clipboard
    const categoryUrl = window.location.href; // Get the current page URL
    const textArea = document.createElement("textarea");
    textArea.value = categoryUrl;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    // Show a toast notification
    toast.success("Link copied to clipboard!");
  };
  

  useEffect(() => {
    // Fetch artists when the component mounts
    findArtists(song.id)
      .then((res) => {
        const artistInfo = res.data.artist;
        const artistIds = Array.isArray(artistInfo)
          ? artistInfo.map((a) => a.person)
          : [artistInfo.person];

        const artistsData = artistIds.map((id) => {
          const person = res.data.users.find((user) => user.id === id);
          return person ? person.name : null;
        });

        setArtists(artistsData.filter(Boolean));
      })
      .catch((error) => {
        console.error("Error fetching artists:", error);
      });
  }, [song.id]);

  return (
    <div className="row m-auto align-items-center play-text p-2 br-color">
      <div
        className="col-1 music-play"
        data-music-url={PORT + song.audio_file}
        data-category-id={song.category}
      >
        <small
          className="text-white p-2 playpause-icon play-button"
          data-state="play"
        >
          <PlayPause
            isPlaying={isPlaying}
            activeSong={activeSong}
            song={song}
            handlePause={handlePauseClick}
            handlePlay={handlePlayClick}
          />
        </small>
      </div>
      <div className="col-4 col-md-4 d-flex align-items-center">
        <img
          src={
            song.poster ? PORT + song.poster : "/assets/images/TheBuzzThumb.png"
          }
          width="50"
          height="50"
          alt=""
          className="img-fluid"
        />
        <p className="d-block w-100 text-white  fs-6 mx-2 mb-0">
          {song.name}
        </p>
      </div>
      <div className=" col-4 col-md-4  align-items-center">
        <p className="d-flex text-white-50 mx-2 mt-0 play-text">
          {artists.length > 0 ? artists.join(", ") : "No Artist"}
        </p>
        
      </div>
      <div className="col-1 d-none d-md-flex gap-2 align-items-center">
       
        {song.count}
      </div>
      <div className="col-1 d-none d-md-flex align-items-center">
        {song.duration}
      </div>
      <div className="col-1 d-none gap-2 d-md-flex align-items-center">
        <i
          className="fa-solid fa-share-nodes"
          data-music-url={PORT + song.audio_file}
          onClick={handleCopyLink}
        ></i>
        {song.url && (
          <a href={song.url} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-white-50 float-end">
            <i className="fa-brands fa-youtube"></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default MusicListItem;
