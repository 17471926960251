import React from 'react';
import { Col, Image } from 'react-bootstrap';
import PORT from '../const';

const CategoryItem = ({ item, onClick }) => {
 
  return (
    <Col md={2} sm={6} xs={6} className="">
    <a className="text-decoration-none" onClick={onClick}>
      <div className="col-md-12 mt-4 br-15">
        <div className="image-container position-relative">
          <Image src={item.poster ? PORT + item.poster : "/assets/images/TheBuzzThumb.png"} alt="" fluid className="br-15" />
          <div className="d-flex align-items-end position-absolute bottom-0 w-100 h-100 start-0 text-white p-2 gradient-background">
            <div className="d-flex justify-content-start align-items-center">
              <div
                id="myClickableDiv"
                className="d-inline-flex rounded-circle cus-size p-2 bg-white justify-content-center align-items-center"
                data-url={item.id}
              >
                <i
                  id="play-pause-button"
                  className="fa fa-play text-primary align-content-center playicon"
                  aria-hidden="true"
                />
              </div>
              <p className="d-block music-title mx-2  quicksand">
                {item.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  </Col>  
  );
};

export default CategoryItem;
