import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCategorys } from "../../api";

const initialState = {
  currentSongs: [],
  currentIndex: 0,
  isActive: false,
  isPlaying: false,
  activeSong: {},
  genreListId: "",
  categories: [],
  searchValue: '', // Initialize searchValue
  search: {
    results: [] // Initial empty array or default value
  },
  latest: [],

   // Store category data
};

// Create an async action to fetch categories
export const fetchCategories = createAsyncThunk(
  "player/fetchCategories",
  async () => {
    try {
      const data = await getAllCategorys();
      // console.log(data); // Use the API function
      return data.cur_cat;
    } catch (error) {
      throw error;
    }
  }
);

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setActiveSong: (state, action) => {
      state.activeSong = action.payload.song;

      if (action.payload?.data?.tracks?.hits) {
        state.currentSongs = action.payload.data.tracks.hits;
      } else if (action.payload?.data?.properties) {
        state.currentSongs = action.payload?.data?.tracks;
      } else {
        state.currentSongs = action.payload.data;
      }

      state.currentIndex = action.payload.i;
      state.isActive = true;
    },

    nextSong: (state, action) => {
      if (state.currentSongs[action.payload]?.track) {
        state.activeSong = state.currentSongs[action.payload]?.track;
      } else {
        state.activeSong = state.currentSongs[action.payload];
      }

      state.currentIndex = action.payload;
      state.isActive = true;
    },

    prevSong: (state, action) => {
      if (state.currentSongs[action.payload]?.track) {
        state.activeSong = state.currentSongs[action.payload]?.track;
      } else {
        state.activeSong = state.currentSongs[action.payload];
      }

      state.currentIndex = action.payload;
      state.isActive = true;
    },

    playPause: (state, action) => {
      state.isPlaying = action.payload;
    },

    selectGenreListId: (state, action) => {
      state.genreListId = action.payload;
    },

    setCategories: (state, action) => {
      state.categories = action.payload; // Update categories in the state
    },

    updateSearchResults: (state, action) => {
      state.search.results = action.payload;
    },

    setLatest: (state, action) => {
      state.latest = action.payload;
    },
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        // Handle error or provide appropriate feedback
        console.error('Error fetching categories:', action.error);
      });
  },
});

export const {
  setActiveSong,
  nextSong,
  prevSong,
  playPause,
  selectGenreListId,
  setCategories,
  updateSearchResults,
  setLatest,
  
} = playerSlice.actions;

export default playerSlice.reducer;
