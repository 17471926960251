// SidbarFull.jsx

import React from "react";
import SidebarComponent from "./sidbar";
import RecentUpdatesComponent from "./recent";

export default function SidbarFull() {


  return (
    
    <div className="sidbar">
      <SidebarComponent />
      <RecentUpdatesComponent />
      
    </div>
  );
}
