import { TransitionGroup, CSSTransition } from "react-transition-group";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Container, Row, div } from "react-bootstrap";
import { fetchCategories } from "../redux/features/playerSlice";
import HeaderComponent from "../components/navbar";
import CategoryItem from "../components/category";
import PodcastComponent from "../components/bannerSmall";
import MusicList from "../components/musicList";
import FooterComponent from "../components/footer";
import SidbarFull from "../components/sidbarFull";
import JoinUsBanner from "../components/banner";
import MusicPlayer from "../components/MusicPlayer/index";

const Home = () => {
  const dispatch = useDispatch();
  const { activeSong } = useSelector((state) => state.player);
  const imageUrl = "assets/images/thubmnail-podcast.png";
  const categories = useSelector((state) => state.player.categories);
  const navigate = useNavigate();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
    navigate(`/category/${categoryId}`);
  };

  return (
    <div className="container-fluid w-100">
      <div className="row w-100">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <SidbarFull />
        </div>
        <div className="col-md-9">
          <HeaderComponent />
          <TransitionGroup>
            {/*
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          */}
            <CSSTransition
              key={location.pathname}
              classNames="fade"
              timeout={1000}
            >
              <Routes>
                <Route
                  path="*"
                  element={
                    <div>
                      <JoinUsBanner imageUrl={imageUrl} />
                      <Row className="">
                        {categories.map((category) => (
                          <CategoryItem
                            item={category}
                            key={category.id}
                            onClick={() => handleCategoryClick(category.id)}
                          />
                        ))}
                      </Row>
                    </div>
                  }
                />
                <Route
                  path="/category/:selectedCategoryId"
                  element={
                    <div className="row mt-4">
                      <div>
                        <PodcastComponent />
                        <Row className="mt-4">
                          <MusicList />
                        </Row>
                      </div>
                    </div>
                  }
                />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
      <FooterComponent />
      {activeSong?.name && <MusicPlayer />}
    </div>
  );
};

export default Home;
