import React, { useEffect, useState } from "react";
import PORT from "../../const";
import { findCategory } from "../../api";

const Track = ({ isPlaying, isActive, activeSong }) => {
  const [categoryName, setCategoryName] = useState(null);

  useEffect(() => {
    if (activeSong?.category) {
      findCategory(activeSong.category)
        .then((res) => {
          // console.log(res.data.category)
          setCategoryName(res.data.category.name); // Assuming the API response contains the category name
        })
        .catch((error) => {
          console.error("Error fetching category:", error);
        });
    }
  }, [activeSong]);

  return (
    <div className="col-md-12 col-sm-8 d-flex align-items-center justify-center ">
      <div className="d-none d-md-block h-16 w-16 mr-4">
       
          <img
            src={PORT + activeSong?.poster}
            alt="cover art"
            className="rounded-full track-image br-15 w-25 m-3 float-end"
          />
        </div>
      
      <div>
      <p
        className="text-truncate d-block text-truncate text-white fs-6 mx-2 mb-0 track-name"
        id="song-title"
        style={{ maxWidth: '80%' }}
      >
        {activeSong?.name ? activeSong?.name : "No active Song"}
      </p>
      <p
        className="d-block text-truncate text-white-50 mx-2 mt-0 play-text track-artist"
        id="song-artist"
      >
      {categoryName !== null ? categoryName : 'No active Category'}
      </p>
      </div>
    </div>
  );
};

export default Track;
